import React from 'react';
import css from "./LayoutFooter.module.scss"
import TitleLogo from "./TitleLogo/TitleLogo"
import {observer} from "mobx-react";
import MiddleTitle from "./MiddleTitle/MiddleTitle";
import NavigationFooter from "./NavigationFooter/NavigationFooter";
import {RouterNames} from "../../../../../shared/enums/RouterNames";
import {version} from "../../../../../app/utils/version";

const titleName = "ВІЙСЬКОВИЙ ІНСТИТУТ\nТЕЛЕКОМУНІКАЦІЙ ТА ІНФОРМАТИЗАЦІЇ\nІМЕНІ ГЕРОЇВ КРУТ"
const middleTitle = "Вищий військовий навчальний заклад України, що готує офіцерів військ зв'язку для усіх \n" +
    "силових структур України (ЗСУ, СБУ, ДПСУ, МВС, МНС, СЗР).\n"

export const LayoutFooter = observer(() => {


    const navArray = [
        [
            RouterNames.WHY_MITIT,
            RouterNames.HISTORY,
            RouterNames.SUPERIORS,
            RouterNames.PUBLIC_INFO,
            RouterNames.HEROES
        ],
        [
            RouterNames.EDUCATIONAL_ACTIVITIES,
            RouterNames.METHODICAL_WORK,
            RouterNames.MATERIAL_AND_TECHNICAL_BASE,
            RouterNames.LICENSE_CERTIFICATIONS_ACCREDITATION,
            RouterNames.REGULATORY_FRAMEWORK
        ],
        [
            RouterNames.FULL_TIME_EDUCATION,
            RouterNames.EXTRAMURAL_STUDIES,
            RouterNames.DOCTORAL_STUDIES_ADJUNCT_STUDIES,
            RouterNames.PROGRAM_OF_RESERVE_OFFICERS,
            RouterNames.RECEPTION,
            RouterNames.EXAM_2020
        ],
        [
            RouterNames.RECEPTION_OF_CITIZENS,
            RouterNames.ADDRESS,
            RouterNames.HOTLINE
        ]
    ]

    return (
        <div>
            <div className={css.footerMainContainer}>
                <div className={css.footerUpperContainer}>
                    <TitleLogo value={titleName} className={css.whiteLogo}/>
                    <MiddleTitle value={middleTitle}/>
                </div>
                <div className={css.footerBottomContainer}>
                    {navArray.map((value, index) => (
                        <NavigationFooter key={index} index={index} value={value}/>
                    ))}
                </div>
            </div>
            <div className={css.footerWhiteContainer}>
                <div>© Військовий інститут телекомунікацій та інформатизації, 2009-{new Date().getFullYear()}.</div>
                <div>{version}</div>
            </div>
        </div>
    )
})

