import React, {FC, useEffect, useRef, useState} from 'react';
import {Image} from "../../../../entities/image";
import {Video} from "../../../../entities/video";
import {ImageComponent} from "../../../../features/ImageComponent";
import {VideoComponent} from "../../../../features/VideoComponent";
import css from "./Slider.module.scss"
import {observer} from "mobx-react";

interface SliderProps {
    images: Image[];
    videos: Video[];
}

const Slider: FC<SliderProps> = observer(({images, videos}) => {
    const [currentMedia, setCurrentMedia] = useState<Image | Video>({} as Image | Video)
    const [onChange, setOnChange] = useState(false)
    useEffect(() => {
        if (images.length > 0) {
            setCurrentMedia(images[0])
        } else if (videos.length > 0) {
            setCurrentMedia(videos[0])
        }
    }, [images, videos]);

    useEffect(() => {
        scrollOnActive()
    }, [currentMedia]);

    const animate = () => {
        setOnChange(true)
        setTimeout(() => {
            setOnChange(false)
        }, 400)
    }

    const [dragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const containerRef = useRef(null);

    const handleMouseDown = (e: any) => {
        setDragging(true);
        // @ts-ignore
        setStartX(e.pageX - containerRef.current.offsetLeft);
        // @ts-ignore
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseMove = (e: any) => {
        if (!dragging) return;
        // @ts-ignore
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = x - startX;
        // @ts-ignore
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const scrollOnActive = () => {
        if (document.getElementById("preview-container")) {
            // @ts-ignore
            document.getElementById("preview-container").scrollTo({
                left: 150 * (images?.indexOf(currentMedia as Image) !== -1 ? images.indexOf(currentMedia as Image) : videos.indexOf(currentMedia as Video)),
                behavior: "smooth"
            })
        }
    }

    const prevMedia = () => {
        animate()
        setTimeout(() => {
            const currentIndex = images.indexOf(currentMedia as Image) !== -1 ? images.indexOf(currentMedia as Image) : videos.indexOf(currentMedia as Video);
            const newIndex = (currentIndex - 1) < 0 ? (images.length + videos.length - 1) : (currentIndex - 1);
            setCurrentMedia(newIndex < images.length ? images[newIndex] : videos[newIndex - images.length]);
        }, 300)
    }

    const nextMedia = () => {
        animate()
        setTimeout(() => {
            const currentIndex = images.indexOf(currentMedia as Image) !== -1 ? images.indexOf(currentMedia as Image) : videos.indexOf(currentMedia as Video);
            const newIndex = (currentIndex + 1) === (images.length + videos.length) ? 0 : (currentIndex + 1);
            setCurrentMedia(newIndex < images.length ? images[newIndex] : videos[newIndex - images.length]);
        }, 300)
    }

    return (
        <div className={css.container}>
            <div className={css.sliderContainer}>
                <button className={css.leftArrow} onClick={prevMedia}>
                    <i className="bi bi-caret-left"></i>
                </button>

                {images.indexOf(currentMedia as Image) !== -1 ? (
                    <ImageComponent className={`${css.currentImg} ${onChange && css.currentImg_change}`}
                                    imgId={(currentMedia as Image)?.id}/>
                ) : (
                    <VideoComponent className={`${css.currentImg} ${onChange && css.currentImg_change}`}
                                    videoId={(currentMedia as Video)?.id}
                                    isFullSize={true}/>
                )}

                <button className={css.rightArrow} onClick={nextMedia}>
                    <i className="bi bi-caret-right"></i>
                </button>
            </div>
            <div ref={containerRef}
                 onMouseDown={handleMouseDown}
                 onMouseUp={handleMouseUp}
                 onMouseMove={handleMouseMove}
                 id={"preview-container"} className={css.previewContainer}>
                {
                    images?.map(item => (
                        <button onClick={() => {
                            animate()
                            setCurrentMedia(item)
                        }} className={css.imgBtn}>
                            <ImageComponent key={`slider-img-news-page_${item.id}`}
                                            className={`${css.previewElement} ${item?.id === (currentMedia as Image)?.id && css.imgBtn_active}`}
                                            imgId={item?.id}/>
                        </button>
                    ))
                }
                {
                    videos?.map(item => (
                        <button onClick={() => {
                            animate()
                            setCurrentMedia(item)
                        }} className={css.imgBtn}>
                            <VideoComponent key={`slider-video-news-page_${item.id}`}
                                            className={`${css.previewElement} ${item?.id === (currentMedia as Video)?.id && css.imgBtn_active}`}
                                            videoId={item?.id}
                                            isFullSize={false}/>
                        </button>
                    ))
                }
            </div>
        </div>
    );
});

export default Slider;