import React, {FC, useState, useEffect} from 'react';
import css from "./VideoComponent.module.scss"
import Skeleton from "shared/ui/Skeleton/Skeleton";
import {API_URL, VIDEOS_PATH} from "../../../shared/api";

interface VideoProps extends React.HTMLProps<HTMLElement> {
    videoId: number;
    onLoaded?: () => any;
    isFullSize: boolean;
}

export const VideoComponent: FC<VideoProps> = ({videoId, className, onLoaded, isFullSize}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [videoSrc, setVideoSrc] = useState<string | null>(null);

    useEffect(() => {
        if (videoId !== undefined && !isNaN(videoId)) {
            setVideoSrc(`${VIDEOS_PATH}${videoId}.mp4`);
        } else {
            setVideoSrc(null);
        }
    }, [videoId]);

    const handleVideoLoad = () => {
        setIsLoading(false);
        if (onLoaded) onLoaded();
    };

    const handleVideoError = () => {
        setIsLoading(false);
    };

    return (
        <>
            {isLoading && <Skeleton className={`${css.skeletonSize}`}/>}
            {videoSrc && (
            <video key={`video_${videoId}`}
                 style={{display: isLoading ? 'none' : 'block'}}
                 onLoadedData={handleVideoLoad}
                 onError={handleVideoError}
                 className={`${className} ${css.video}`}
                 controls={isFullSize}
                 src={VIDEOS_PATH + videoId}/>
            )}
        </>
    );
};